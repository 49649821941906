import api from '../api';
import { loadAbort } from './../helpers/LoadAbort.utility';

export const getCurrentUser = (token: string) => {
    const controller = loadAbort();
    addToken();
    return { call: api.get('api/User/current', { headers: { authentication: token }, signal: controller.signal }), controller };
};

const addToken = () => {
    const tokenLocalStorage = localStorage.getItem('token');
    if (tokenLocalStorage) {
        api.interceptors.request.use(
            (config) => {
                const token = `Bearer ${tokenLocalStorage}`;
                if (token) {
                    config.headers['Authorization'] = token;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            },
        );
    }
};
