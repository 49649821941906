import { AdsTypeEnum } from './../Enums/AdsType.enum';

export type AdsDimension = {
    label: string;
    width: number;
    height: number;
};

export const AdsDimensions = {
    [AdsTypeEnum.MediumRectangle]: {
        label: 'MediumRectangle',
        width: 300,
        height: 250,
    },
    [AdsTypeEnum.LargeRectangle]: {
        label: 'LargeRectangle',
        width: 336,
        height: 280,
    },
    [AdsTypeEnum.LeaderBoard]: {
        label: 'LeaderBoard',
        width: 728,
        height: 90,
    },
    [AdsTypeEnum.Skyscrapper]: {
        label: 'Skyscrapper',
        width: 300,
        height: 600,
    },
    [AdsTypeEnum.MobileLeaderboard]: {
        label: 'MobileLeaderboard',
        width: 320,
        height: 50,
    },
};
