import React, { useState } from 'react';
import { News } from '../../models';
import LastNewsArticle from '../News/LastNewsArticle';
import { useFetchAndLoad } from '../../hooks/useFetchAndLoad.hook';
import { getAllNews } from '../../services/News.service';
import { useAsync } from '../../hooks';
import LoadingComponent from '../Templates/LoadingComponent';

const LastNewsSection: React.FC = () => {
    const { loading, callEndpoint } = useFetchAndLoad();
    const [data, setData] = useState<News[]>([]);
    const getNewsApiCall = async () => await callEndpoint(getAllNews(3));

    const adaptData = (backData: any) => {
        if (backData.items.length > 0) setData(backData.items);
    };

    useAsync(getNewsApiCall, adaptData, []);

    return (
        <section className="last-news-section">
            <div className="title">
                <h3>Últimas Noticias</h3>
            </div>
            <div className="last-news">
                {!loading ? (
                    data.map((news) => {
                        return <LastNewsArticle {...news} key={news.id} />;
                    })
                ) : (
                    <LoadingComponent />
                )}
            </div>
        </section>
    );
};

export default LastNewsSection;
