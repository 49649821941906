import React from 'react';
import { Link } from 'react-router-dom';
import { DateTimeAgo } from '../../helpers';
import { LastNews } from '../../models';
import ImageContainer from '../Templates/ImageContainer';

const LastNewsArticle: React.FC<LastNews> = (news) => {
    return (
        <Link className="last-news-redirect" to={`/news/${news.id}`}>
            <article className="last-news-article">
                <ImageContainer img={news.img} />
                <div className="info-container">
                    <div className="date">{DateTimeAgo(news.date)}</div>
                    <div className="title">{news.title}</div>
                </div>
            </article>
        </Link>
    );
};

export default LastNewsArticle;
