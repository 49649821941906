import React, { useContext, useRef, useState } from 'react';
import SidebarAds from '../../components/Ads/SidebarAds';
import { CalendarEvent, FormCalendarEvent } from '../../models';
import ShareIcon from '../../assets/icons/ShareIcon';
import BottomAdd from '../../components/Ads/BottomAds';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsync, useAuth } from '../../hooks';
import { useFetchAndLoad } from '../../hooks/useFetchAndLoad.hook';
import RemoveIcon from '../../assets/icons/RemoveIcon';
import ImageFieldContainer from '../../components/Templates/ImageFieldContainer';
import AutoSizeTextarea from '../../components/Templates/AutoSizeTextarea';
import { FormValidator } from '../../helpers';
import SaveIcon from '../../assets/icons/SaveIcon';
import NotFoundPage from '../NotFoundePage';
import { NotifyContext } from '../../context/NotifyContext';
import CalendarDictionary from '../../dictionaries/CalendarDictionary.json';
import { createCalendarEvent, editCalendarEvent, getCalendarEventById, removeCalendarEvent } from '../../services';
import LoadingIcons from 'react-loading-icons';
import { ImageUploader } from '../../helpers/ImageUploader.utility';

const calendarEntityNames: any = CalendarDictionary.ln_es.entity;

const newCalendarEvent: CalendarEvent = {
    id: '',
    img: '',
    title: '',
    description: '',
    date: '',
};

const CalendarFormPage: React.FC = () => {
    const { user } = useAuth();
    const { notifyError, notifySuccess } = useContext(NotifyContext);
    const { callEndpoint } = useFetchAndLoad();
    const { loading: loadingImage, callEndpoint: imageCallEndpoint } = useFetchAndLoad();
    const { loading, callEndpoint: formCallEndpoint } = useFetchAndLoad();
    const [data, setData] = useState<CalendarEvent>(newCalendarEvent);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const formRef = useRef<HTMLFormElement>(null);

    const getCalendarEventApiCall = async () => await callEndpoint(getCalendarEventById(pathname.split('/').slice(-1).toString()));

    const adaptData = (backData: any) => {
        if (backData) {
            setData(backData);
        }
    };

    const handleRemove = async () => {
        if (!loading) {
            if (confirm('¿Seguro que desea eliminar esta noticia?')) {
                try {
                    const result = await callEndpoint(removeCalendarEvent(data.id));
                    if (result) {
                        notifySuccess('La noticia se ha eliminado correctamente');
                        navigate('/calendario');
                    }
                } catch (e) {
                    notifyError('Ha ocurrido un error');
                    console.log(e);
                }
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const imageName: string = e.currentTarget.image.value;
        const imageFile = e.currentTarget.image.files[0];

        const body = {
            description: e.currentTarget.description.value,
            title: e.currentTarget.calendarEventTitle.value,
            img: imageName,
        };

        if (FormValidator(newCalendarEvent, body, calendarEntityNames, notifyError, ['video'])) {
            let imageId: string | null = data.img;

            if (imageName != '' && (!imageName.includes(imageId) || imageId == '')) {
                imageId = await ImageUploader(imageFile, notifyError, imageCallEndpoint);
            }

            if (imageId) {
                const sendCalendarEvent: FormCalendarEvent = {
                    content: body.description,
                    title: body.title,
                    imageId: imageId,
                };

                saveCalendarEvent(sendCalendarEvent);
            } else {
                notifyError('Hubo un error al guardar la imagen');
            }
        }
    };

    const saveCalendarEvent = async (newsData: FormCalendarEvent) => {
        let result: any;
        try {
            if (pathname.includes('edit')) result = await formCallEndpoint(editCalendarEvent(data.id, newsData));
            else result = await formCallEndpoint(createCalendarEvent(newsData));

            if (result) {
                navigate(`/calendario`);
                notifySuccess('El evento se ha guardado de forma correcta');
            }
        } catch (e) {
            notifyError('Hubo un error al guardar el evento');
            console.log(e);
        }
    };

    if (pathname.includes('edit')) useAsync(getCalendarEventApiCall, adaptData, []);

    if (user)
        return (
            <main className="news-detail-page">
                <div className="page-container">
                    <div className="content-container">
                        <div className="content">
                            {data.id && (
                                <div className="action-buttons" style={{ justifyContent: 'flex-end' }}>
                                    <div onClick={handleRemove} className="remove-container">
                                        <RemoveIcon className="tiny-static-plus-icon" />
                                        <span>Eliminar</span>
                                    </div>
                                </div>
                            )}
                            {data != null && (
                                <form ref={formRef} className="news-form" onSubmit={handleSubmit}>
                                    <ImageFieldContainer img={data.img} />
                                    <div className="share-date-container">
                                        <ShareIcon className="small-less-icon" />
                                        <div className="date">Justo Ahora</div>
                                    </div>
                                    <div className="title-field">
                                        <label htmlFor="title">Título</label>
                                        <AutoSizeTextarea className="form-field" id="calendarEventTitle" text={data.title} />
                                    </div>
                                    <div className="description-field">
                                        <label htmlFor="description">Descripción</label>
                                        <AutoSizeTextarea className="form-field" id="description" text={data.description} />
                                    </div>
                                    <div className="buttons-container">
                                        <button type="button" className="cancel-button" onClick={() => navigate(-1)}>
                                            Cancelar
                                        </button>
                                        <button type="submit" className="submit-button">
                                            {!loading && !loadingImage ? (
                                                <>
                                                    <SaveIcon className="tiny-icon" />
                                                    <span>Guardar</span>
                                                </>
                                            ) : (
                                                <LoadingIcons.Oval width="5rem" height="1.5rem" />
                                            )}
                                        </button>
                                    </div>
                                </form>
                            )}
                            <BottomAdd />
                        </div>
                        <SidebarAds />
                    </div>
                </div>
            </main>
        );
    else return <NotFoundPage />;
};

export default CalendarFormPage;
