import React from 'react';
import IconProps from './IconProps.type';

const UserIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 60 58" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.0906 58C10.2509 57.8563 9.3953 57.7542 8.57539 57.5627C6.16523 57.0787 4.02163 55.9654 2.47898 54.3965C0.936334 52.8276 0.0813953 50.8914 0.0475305 48.8898C-0.302674 44.4336 0.547228 39.969 2.53895 35.8023C3.72162 33.0665 6.08834 30.7659 9.20518 29.3224C11.213 28.4457 13.4695 28.0057 15.7526 28.0456C16.5167 28.1095 17.2524 28.3137 17.9034 28.6425C18.9688 29.1085 19.9591 29.6895 20.9929 30.2066C23.6527 31.6721 26.7997 32.4561 30.0218 32.4561C33.2438 32.4561 36.3908 31.6721 39.0507 30.2066C39.8865 29.7884 40.7381 29.3862 41.5223 28.9138C42.2036 28.5244 42.9784 28.2542 43.797 28.1206C44.6156 27.987 45.4598 27.993 46.2754 28.1381C48.4442 28.3724 50.5081 29.0334 52.2848 30.0628C54.0615 31.0921 55.4965 32.4581 56.4629 34.0403C58.3357 36.9643 59.4503 40.165 59.7386 43.4473C59.9443 45.1371 60.0356 46.8346 60.0119 48.5323C60.0537 50.8926 58.9773 53.177 57.0021 54.92C55.0269 56.6631 52.3013 57.7337 49.3808 57.9138C49.2254 57.9382 49.073 57.9735 48.9253 58.0192L11.0906 58Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M46.8973 13.9494C46.9019 16.711 45.891 19.4117 43.9921 21.7105C42.0933 24.0094 39.3917 25.8031 36.2286 26.8653C33.0655 27.9275 29.5828 28.2104 26.2202 27.6784C22.8577 27.1464 19.7662 25.8232 17.3363 23.8761C14.9063 21.9289 13.2469 19.4451 12.5675 16.7383C11.8882 14.0316 12.2194 11.2233 13.5193 8.66815C14.8193 6.11301 17.0297 3.92563 19.8713 2.38228C22.7129 0.83893 26.0584 0.00883997 29.485 -0.00315022C34.0893 -0.00995542 38.5091 1.45447 41.7759 4.06922C45.0427 6.68396 46.89 10.2357 46.9131 13.9462"
                fill={props.fill || 'white'}
            />
        </svg>
    );
};

export default UserIcon;
