import axios from 'axios';
import { Configurations } from '../config/EnvSettings';

export default axios.create({
    baseURL: Configurations.API_URL,
    timeout: 100000,
    headers: {
        accept: 'text/plain',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Origin': '*',
        'X-Content-Type-Options': 'nosniff',
        'X-Frame-Options': 'deny',
    },
});
