import React from 'react';
import IconProps from './IconProps.type';

const ShareIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.576 15.271L6.46602 12.484C5.97551 12.9709 5.35183 13.3017 4.67361 13.4346C3.99538 13.5676 3.29298 13.4968 2.65494 13.2311C2.01691 12.9654 1.47181 12.5168 1.08837 11.9418C0.704925 11.3668 0.500305 10.6911 0.500305 10C0.500305 9.30887 0.704925 8.63321 1.08837 8.0582C1.47181 7.48319 2.01691 7.03457 2.65494 6.7689C3.29298 6.50324 3.99538 6.43242 4.67361 6.56538C5.35183 6.69833 5.97551 7.02911 6.46602 7.516L11.576 4.729C11.4008 3.90674 11.5273 3.04886 11.9326 2.31226C12.3379 1.57565 12.9948 1.00954 13.7831 0.717437C14.5715 0.425339 15.4386 0.426778 16.226 0.72149C17.0134 1.0162 17.6684 1.58449 18.0712 2.32244C18.4741 3.06039 18.5978 3.91868 18.4198 4.74036C18.2418 5.56204 17.774 6.29219 17.1019 6.7973C16.4298 7.30242 15.5984 7.54873 14.7596 7.4912C13.9209 7.43367 13.1308 7.07615 12.534 6.484L7.42402 9.271C7.52595 9.75166 7.52595 10.2483 7.42402 10.729L12.534 13.516C13.1308 12.9239 13.9209 12.5663 14.7596 12.5088C15.5984 12.4513 16.4298 12.6976 17.1019 13.2027C17.774 13.7078 18.2418 14.438 18.4198 15.2596C18.5978 16.0813 18.4741 16.9396 18.0712 17.6776C17.6684 18.4155 17.0134 18.9838 16.226 19.2785C15.4386 19.5732 14.5715 19.5747 13.7831 19.2826C12.9948 18.9905 12.3379 18.4244 11.9326 17.6877C11.5273 16.9511 11.4008 16.0933 11.576 15.271Z"
                fill={props.fill || '#002046'}
            />
        </svg>
    );
};

export default ShareIcon;
