import React, { useState } from 'react';
import AddIcon from '../../assets/icons/AddIcon';
import { useAsync, useAuth, useFetchAndLoad } from '../../hooks';
import { Ads } from '../../models';
import { getAllAds } from '../../services';
import FormAdsArticle from '../../components/Ads/FormAdsArticle';
import NotFoundPage from '../NotFoundePage';
import LoadingComponent from '../../components/Templates/LoadingComponent';
import ListPagination from '../../components/Templates/ListPagination';

const AdsPage: React.FC = () => {
    const { user } = useAuth();
    const { loading, callEndpoint } = useFetchAndLoad();
    const [data, setData] = useState<Ads[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const getAllAdsApiCall = async () => await callEndpoint(getAllAds(10, pageNumber));

    const adaptData = (backData: any) => {
        if (backData.items) setData(backData.items);
    };

    useAsync(getAllAdsApiCall, adaptData, []);

    if (user)
        return (
            <main className="ads-page">
                <div className="page-container">
                    <div className="content-container">
                        <div className="content">
                            <div className="content-title">
                                <h2 className="title">Anuncios</h2>
                                <div className="add-container" onClick={() => setShowAdd(true)}>
                                    <AddIcon className="tiny-static-plus-icon" />
                                    <span>Agregar anuncio</span>
                                </div>
                            </div>
                            <section className="ads-list">
                                {showAdd && <FormAdsArticle setShowAdd={setShowAdd} />}
                                {!loading ? (
                                    data.length > 0 ? (
                                        data.map((ads, i) => {
                                            return <FormAdsArticle ads={ads} key={i} />;
                                        })
                                    ) : (
                                        <h4 className="empty">No se ha publicado ningún anuncio</h4>
                                    )
                                ) : (
                                    <LoadingComponent />
                                )}
                                <ListPagination
                                    pageNumber={pageNumber}
                                    setPageNumber={setPageNumber}
                                    getApiCall={getAllAdsApiCall}
                                    adaptData={adaptData}
                                />
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        );
    else return <NotFoundPage />;
};

export default AdsPage;
