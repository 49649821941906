import React from 'react';
import IconProps from './IconProps.type';

const EditIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2856 1L13.0633 3.22222H2.22222V18.7778H17.7778V7.93667L20 5.71444V19.8889C20 20.1836 19.8829 20.4662 19.6746 20.6746C19.4662 20.8829 19.1836 21 18.8889 21H1.11111C0.816426 21 0.533811 20.8829 0.325437 20.6746C0.117063 20.4662 0 20.1836 0 19.8889V2.11111C0 1.81643 0.117063 1.53381 0.325437 1.32544C0.533811 1.11706 0.816426 1 1.11111 1H15.2856ZM19.4278 0L21 1.57333L10.7867 11.7867L9.21778 11.79L9.21556 10.2156L19.4278 0Z"
                fill={props.fill || '#9a0018'}
            />
        </svg>
    );
};

export default EditIcon;
