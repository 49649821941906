import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import AddIcon from '../../assets/icons/AddIcon';
import BottomAdd from '../../components/Ads/BottomAds';
import SidebarAds from '../../components/Ads/SidebarAds';
import NewsArticle from '../../components/News/NewsArticle';
import ListPagination from '../../components/Templates/ListPagination';
import LoadingComponent from '../../components/Templates/LoadingComponent';
import { useAuth } from '../../hooks';
import { useFetchAndLoad } from '../../hooks/useFetchAndLoad.hook';
import { News } from '../../models';
import { getNewsByName } from '../../services/News.service';

const NewsSearchPage: React.FC = () => {
    const { user } = useAuth();
    const location = useLocation();
    const [error, setError] = useState<string>('');
    const { loading, callEndpoint } = useFetchAndLoad();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [data, setData] = useState<News[]>([]);

    const getNewsApiCall = async () => {
        const name = location.pathname.split('/')[2];
        if (name != '') {
            setError('');
            return await callEndpoint(getNewsByName(name, 10, pageNumber));
        } else setError('Debe ingresar algún nombre para la búsqueda');
    };

    const adaptNewsdata = (data: any) => {
        if (data.items) setData(data.items);
    };

    useEffect(() => {
        setPageNumber(1);
    }, [location.pathname]);

    return (
        <main className="news-page">
            <div className="page-container">
                <div className="content-container">
                    <div className="content">
                        <div className="content-title">
                            <h2 className="title">Noticias</h2>
                            {user && (
                                <Link to="/create/news" className="add-container">
                                    <AddIcon className="tiny-static-plus-icon" />
                                    <span>Nueva noticia</span>
                                </Link>
                            )}
                        </div>
                        <section className="news-list">
                            {!loading ? (
                                error == '' ? (
                                    data.length > 0 ? (
                                        data.map((news, i) => (
                                            <React.Fragment key={i}>
                                                <NewsArticle {...news} />
                                                {i + 1 != data.length && (i + 1) % 3 == 0 && <BottomAdd />}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <h4 className="empty">No hay noticias publicadas</h4>
                                    )
                                ) : (
                                    <h4 className="empty">{error}</h4>
                                )
                            ) : (
                                <LoadingComponent />
                            )}
                            <ListPagination
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                getApiCall={getNewsApiCall}
                                adaptData={adaptNewsdata}
                                condition={location.pathname}
                            />
                        </section>
                        <BottomAdd />
                    </div>
                    <SidebarAds />
                </div>
            </div>
        </main>
    );
};

export default NewsSearchPage;
