import React, { useState } from 'react';
import { imageLoader } from '../../helpers';
import { useAsync } from '../../hooks';
import { useFetchAndLoad } from '../../hooks/useFetchAndLoad.hook';
import { getImageById } from '../../services';

interface imageContainerProps {
    img?: string;
    style?: React.CSSProperties;
}

const ImageContainer: React.FC<imageContainerProps> = ({ img, style }) => {
    const { callEndpoint } = useFetchAndLoad();
    const [image, setImage] = useState<string>('');
    const getImageApiCall = async () => {
        if (img) return await callEndpoint(getImageById(img));
    };

    const adaptData = (backData: any) => {
        if (backData) setImage(imageLoader(backData));
    };

    useAsync(getImageApiCall, adaptData, [img]);

    return (
        <div className="image-container" style={style && style}>
            {image != '' ? <img src={image} alt="Imagen de evento" /> : <div className="no-image"></div>}
        </div>
    );
};

export default ImageContainer;
