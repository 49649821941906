import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AutoSizeTextarea from '../../components/Templates/AutoSizeTextarea';
import ImageFieldContainer from '../../components/Templates/ImageFieldContainer';
import { useAuth, useFetchAndLoad } from '../../hooks';
import { Desahogo, FormDesahogo } from '../../models';
import LoadingIcons from 'react-loading-icons';
import SidebarAds from '../../components/Ads/SidebarAds';
import NotFoundPage from '../NotFoundePage';
import BottomAds from '../../components/Ads/BottomAds';
import SaveIcon from '../../assets/icons/SaveIcon';
import { FormValidator } from '../../helpers';
import DesahogoDictionary from '../../dictionaries/DesahogoDictionary.json';
import { NotifyContext } from '../../context/NotifyContext';
import { ImageUploader } from '../../helpers/ImageUploader.utility';
import { createDesahogo } from '../../services';

const desahogoEntityNames: any = DesahogoDictionary.ln_es.entity;

const newDesahogo: Desahogo = {
    id: '',
    img: '',
    title: '',
    date: '',
    video: '',
};

const DesahogoFormPage: React.FC = () => {
    const { user } = useAuth();
    const { notifyError, notifySuccess } = useContext(NotifyContext);
    const { loading: loadingImage, callEndpoint: imageCallEndpoint } = useFetchAndLoad();
    const { loading, callEndpoint: formCallEndpoint } = useFetchAndLoad();
    const [data, setData] = useState<Desahogo>(newDesahogo);
    const formRef = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const imageName: string = e.currentTarget.image.value;
        const imageFile = e.currentTarget.image.files[0];

        const body = {
            title: e.currentTarget.desahogoTitle.value,
            img: imageName,
            url: e.currentTarget.video.value,
        };

        if (FormValidator(newDesahogo, body, desahogoEntityNames, notifyError)) {
            let imageId: string | null = data.img;

            if (imageName != '' && (!imageName.includes(imageId) || imageId == '')) {
                imageId = await ImageUploader(imageFile, notifyError, imageCallEndpoint);
            }

            if (imageId) {
                const sendNews: FormDesahogo = {
                    title: body.title,
                    imageId: imageId,
                    video: body.url,
                    content: '',
                };

                saveDesahogo(sendNews);
            } else {
                notifyError('Hubo un error al guardar la imagen');
            }
        }
    };

    const saveDesahogo = async (desahogoData: FormDesahogo) => {
        try {
            const result = await formCallEndpoint(createDesahogo(desahogoData));
            if (result) {
                navigate('/desahogo');
                notifySuccess('El desahogo se ha guardado de forma correcta');
            }
        } catch (e) {
            notifyError('Hubo un error al guardar el desahogo');
            console.log(e);
        }
    };

    if (user)
        return (
            <main className="news-detail-page">
                <div className="page-container">
                    <div className="content-container">
                        <div className="content">
                            <form ref={formRef} className="news-form" onSubmit={handleSubmit}>
                                <ImageFieldContainer img={data.img} />
                                <div className="share-date-container">
                                    <div className="date">Justo Ahora</div>
                                </div>
                                <div className="title-field">
                                    <label htmlFor="title">Título</label>
                                    <AutoSizeTextarea className="form-field" id="desahogoTitle" text={data.title} />
                                </div>
                                <div className="video-field">
                                    <label htmlFor="video">Video</label>
                                    <input className="form-field" type="text" id="video" defaultValue={data.video} />
                                </div>
                                <div className="buttons-container">
                                    <button type="button" className="cancel-button" onClick={() => navigate(-1)}>
                                        Cancelar
                                    </button>
                                    <button type="submit" className="submit-button">
                                        {!loading && !loadingImage ? (
                                            <>
                                                <SaveIcon className="tiny-icon" />
                                                <span>Guardar</span>
                                            </>
                                        ) : (
                                            <LoadingIcons.Oval width="5rem" height="1.5rem" />
                                        )}
                                    </button>
                                </div>
                            </form>
                            <BottomAds />
                        </div>
                        <SidebarAds />
                    </div>
                </div>
            </main>
        );
    else return <NotFoundPage />;
};

export default DesahogoFormPage;
