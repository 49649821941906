import React, { useState } from 'react';
import { useAsync } from '../../hooks';

interface PaginationData {
    pageNumber: number;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
    getApiCall: () => Promise<any>;
    adaptData: (data: any) => void;
    condition?: any;
}

const ListPagination: React.FC<PaginationData> = (props) => {
    const { pageNumber, setPageNumber, getApiCall, adaptData, condition } = props;
    const [totalPages, setTotalPages] = useState<number>(1);

    const handleData = (result: any) => {
        if (result) {
            adaptData(result);
            setTotalPages(result.totalPages);
        }
    };

    const goToPage = (newPage: number) => setPageNumber(newPage);

    const handlePrevPage = () => setPageNumber(pageNumber - 1);

    const handleNextPage = () => setPageNumber(pageNumber + 1);

    const getPageNumbers = () => {
        return Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <div className={`page-${page} ${page == pageNumber ? 'actual' : ''}`} key={page} onClick={() => goToPage(page)}>
                <span>{page}</span>
            </div>
        ));
    };

    useAsync(getApiCall, handleData, [pageNumber, condition]);

    if (totalPages > 1)
        return (
            <div className="pagination-container scroll">
                {pageNumber > 1 && (
                    <div className="prev-page" onClick={handlePrevPage}>
                        <span>{`<`}</span>
                    </div>
                )}
                {getPageNumbers()}
                {/* <div className="middle-pages">. . .</div> */}
                {pageNumber < totalPages && (
                    <div className="next-page" onClick={handleNextPage}>
                        <span>{`>`}</span>
                    </div>
                )}
            </div>
        );
    else return null;
};

export default ListPagination;
