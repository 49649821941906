import React from 'react';
import IconProps from './IconProps.type';

const ImageClipIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 18 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.9392 8.17235L9.60246 7.6271L5.13176 18.5881C4.57653 19.9494 5.23236 21.5087 6.5937 22.064C7.95505 22.6192 9.51433 21.9634 10.0696 20.602L16.0735 5.88077C16.4867 4.86769 16.4807 3.75437 16.0565 2.74587C15.6323 1.73737 14.8408 0.954433 13.8277 0.541238C12.8147 0.128069 11.7013 0.134116 10.6928 0.55829C9.68426 0.982484 8.90133 1.77402 8.48818 2.78708L1.18815 20.6856C0.630721 22.0523 0.638886 23.5542 1.21112 24.9148C1.78337 26.2753 2.85116 27.3315 4.2179 27.889C5.58463 28.4464 7.08657 28.4382 8.44709 27.866C9.80762 27.2938 10.8639 26.226 11.4213 24.8592L17.1877 10.7208L15.851 10.1756L10.0845 24.314C9.67273 25.3237 8.89244 26.1124 7.88736 26.5352C6.88227 26.9579 5.77276 26.9639 4.76311 26.5522C3.75344 26.1404 2.96467 25.3601 2.54193 24.355C2.11919 23.3499 2.1132 22.2404 2.52493 21.2308L9.825 3.33228C10.3773 1.97814 11.9284 1.32577 13.2825 1.87804C14.6367 2.43035 15.2891 3.98141 14.7368 5.33558L8.73283 20.0567C8.47822 20.681 7.7632 20.9817 7.13894 20.7271C6.51469 20.4725 6.21395 19.7575 6.46856 19.1332L10.9392 8.17235Z"
                fill={props.fill || '#808080'}
            />
        </svg>
    );
};

export default ImageClipIcon;
