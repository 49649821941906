import React, { useRef } from 'react';
import RubenSanchezIcon from '../../assets/icons/RubenSanchezIcon';
import SearchIcon from '../../assets/icons/SearchIcon';
import Logo from '../../assets/images/Logo.png';
import SocialLinks from './SocialLinks';
import { ZoneEnum } from '../../models';
import LinksContainer from './LinksContainer';
import { useNavigate } from 'react-router';
import { useAuth } from '../../hooks';
import UserIcon from '../../assets/icons/UserIcon';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
    const { user, logout } = useAuth();
    const searchRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    const searchNews = () => {
        if (searchRef.current) {
            navigate(`/search/${searchRef.current?.value}`);
            searchRef.current.value = '';
        }
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') searchNews();
    };

    const handleClosSesion = () => {
        logout();
    };

    return (
        <header>
            <div className="header-top">
                <SocialLinks zone={ZoneEnum.Header} />
                <a href="/" className="logo-container">
                    <img className="logo" src={Logo} alt="Logo" />
                    <RubenSanchezIcon className="large-icon" />
                </a>
                <div className="search">
                    <input ref={searchRef} onKeyDown={handleOnKeyDown} className="search-input" type="text" placeholder="Buscar" />
                    <SearchIcon className="small-less-icon" onClick={searchNews} />
                    {user && (
                        <div className="user-operations">
                            <UserIcon className="small-icon" />
                            <div className="operations">
                                <Link to="/ads" className="operation-item">
                                    Anuncios
                                </Link>
                                <div onClick={handleClosSesion} className="operation-item">
                                    Cerrar sesión
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <nav className="header-nav">
                <LinksContainer />
            </nav>
        </header>
    );
};

export default Header;
