import React from 'react';
import IconProps from './IconProps.type';

const RubenSanchezIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 171 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7174 11.018L13.4276 17.6521H8.75916L7.37248 11.6327H5.50047L4.49513 17.6521H0L2.63468 1.82088H9.52183C11.3938 1.82088 12.7805 2.23454 13.6818 3.06186C14.5832 3.88144 15.0339 4.99871 15.0339 6.41366C15.0339 8.67912 13.9284 10.2139 11.7174 11.018ZM6.04359 8.33892H7.82315C9.53338 8.33892 10.3885 7.75902 10.3885 6.59923C10.3885 5.625 9.67205 5.13789 8.23915 5.13789H6.5867L6.04359 8.33892Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M28.7735 5.93814L26.8206 17.6521H22.6259L22.9957 15.4716C22.0635 17.134 20.7693 17.9652 19.113 17.9652C18.1192 17.9652 17.3373 17.6946 16.7672 17.1534C16.2048 16.6121 15.9236 15.9433 15.9236 15.1469C15.9236 14.799 16.0007 14.1727 16.1548 13.268L17.3681 5.93814H21.8517L20.7308 12.6418C20.6306 13.268 20.5806 13.6508 20.5806 13.7899C20.5806 14.3698 20.8463 14.6598 21.3779 14.6598C22.3871 14.6598 23.0535 13.6856 23.377 11.7371L24.3477 5.93814H28.7735Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M36.4002 1.82088L35.4642 7.39948C36.0728 6.67268 36.6159 6.1933 37.0935 5.96134C37.5712 5.72938 38.1066 5.6134 38.6998 5.6134C39.8245 5.6134 40.7258 6.01546 41.4038 6.81959C42.0817 7.61598 42.4207 8.7951 42.4207 10.357C42.4207 12.2745 41.8853 14.0142 40.8144 15.576C39.7436 17.1379 38.2568 17.9188 36.354 17.9188C34.5513 17.9188 33.2956 17.1765 32.5868 15.692L31.2811 17.6521H29.2588L31.8935 1.82088H36.4002ZM34.4357 13.5464C34.4357 13.9794 34.4665 14.2771 34.5282 14.4394C34.5898 14.6018 34.7246 14.7564 34.9326 14.9034C35.1406 15.0503 35.3987 15.1237 35.7069 15.1237C36.0997 15.1237 36.4349 14.9961 36.7122 14.741C36.9972 14.4781 37.2669 13.9021 37.5211 13.0129C37.783 12.1237 37.914 11.1765 37.914 10.1714C37.914 9.00387 37.5057 8.4201 36.6891 8.4201C35.9033 8.4201 35.3371 9.01546 34.9904 10.2062L34.4357 13.5464Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M55.9754 12.6649H47.9327C47.8942 12.982 47.8749 13.2178 47.8749 13.3724C47.8749 13.8827 48.0367 14.2809 48.3603 14.567C48.6915 14.8531 49.1075 14.9961 49.6083 14.9961C50.5558 14.9961 51.226 14.5052 51.6189 13.5232L55.5478 14.1611C55.0394 15.3054 54.2151 16.2332 53.0749 16.9446C51.9425 17.6482 50.6829 18 49.2963 18C47.5552 18 46.1416 17.5052 45.0553 16.5155C43.9768 15.518 43.4376 14.1611 43.4376 12.4446C43.4376 10.5812 44.077 8.98454 45.3558 7.65464C46.6346 6.31701 48.3063 5.6482 50.3709 5.6482C52.1197 5.6482 53.5102 6.16624 54.5425 7.20232C55.5825 8.2384 56.1025 9.61469 56.1025 11.3312C56.1025 11.7178 56.0601 12.1624 55.9754 12.6649ZM51.7692 10.4034C51.8077 10.1637 51.8269 9.97809 51.8269 9.84665C51.8269 9.42912 51.696 9.06572 51.434 8.75644C51.1798 8.44716 50.8216 8.29253 50.3594 8.29253C49.8663 8.29253 49.4195 8.48196 49.0189 8.86082C48.6183 9.23196 48.3718 9.74613 48.2794 10.4034H51.7692ZM48.834 2.67912L54.1612 0L54.9585 2.18041L49.4349 4.18686L48.834 2.67912Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M58.899 5.93814H62.9665L62.6545 7.84021C62.9704 7.05928 63.4866 6.49098 64.203 6.13531C64.9194 5.77191 65.6744 5.59021 66.4679 5.59021C67.4617 5.59021 68.2667 5.85309 68.883 6.37887C69.4993 6.90464 69.8075 7.59665 69.8075 8.4549C69.8075 8.78737 69.769 9.18557 69.6919 9.64948L68.3515 17.6521H63.9141L65.0928 10.6121C65.1698 10.1637 65.2083 9.86598 65.2083 9.71907C65.2083 9.06186 64.8617 8.73325 64.1683 8.73325C63.2362 8.73325 62.6661 9.35954 62.4581 10.6121L61.2794 17.6521H56.9461L58.899 5.93814Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M91.2316 5.49742L87.1756 6.52964C87.06 6.05799 86.7711 5.68686 86.3089 5.41624C85.8467 5.14562 85.3382 5.01031 84.7836 5.01031C83.7435 5.01031 83.2235 5.35825 83.2235 6.05412C83.2235 6.27835 83.2967 6.47165 83.4431 6.63402C83.5895 6.79639 84.0016 6.99742 84.6796 7.23711L87.1871 8.13015C87.8419 8.36211 88.4505 8.70619 89.0129 9.16237C89.5753 9.61082 89.9797 10.1057 90.2262 10.6469C90.4728 11.1804 90.596 11.7526 90.596 12.3634C90.596 13.9639 89.9682 15.2861 88.7125 16.3299C87.4645 17.3737 85.7966 17.8956 83.7089 17.8956C81.9216 17.8956 80.3616 17.5322 79.0289 16.8054C77.7038 16.0786 76.7794 14.9691 76.2555 13.4768L80.8084 12.5838C81.2013 13.7049 82.172 14.2655 83.7204 14.2655C84.3676 14.2655 84.8336 14.1495 85.1187 13.9175C85.4114 13.6778 85.5578 13.4072 85.5578 13.1057C85.5578 12.9124 85.5 12.7423 85.3844 12.5954C85.2766 12.4485 85.1572 12.3441 85.0262 12.2822C84.903 12.2126 84.3059 11.9807 83.2351 11.5863C81.7868 11.0528 80.7699 10.6314 80.1844 10.3222C79.6066 10.0052 79.1213 9.53737 78.7284 8.91881C78.3432 8.30026 78.1506 7.57345 78.1506 6.7384C78.1506 5.29253 78.6783 4.07088 79.7337 3.07345C80.7969 2.0683 82.4185 1.56572 84.5987 1.56572C86.2627 1.56572 87.6879 1.91753 88.8742 2.62113C90.0606 3.32474 90.8464 4.2835 91.2316 5.49742Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M103.284 17.6521H98.7196C98.6965 17.4278 98.685 17.2461 98.685 17.107C98.685 16.7126 98.7119 16.2487 98.7658 15.7152C97.8491 17.1843 96.5703 17.9188 94.9294 17.9188C93.82 17.9188 92.9226 17.5979 92.2369 16.9562C91.5513 16.3144 91.2085 15.5103 91.2085 14.5438C91.2085 13.8247 91.3934 13.1675 91.7631 12.5722C92.1329 11.9691 92.6568 11.4897 93.3347 11.134C94.0126 10.7784 94.706 10.5464 95.4147 10.4381C96.1235 10.3222 97.5101 10.1675 99.5747 9.97423C99.6672 9.68814 99.7134 9.46392 99.7134 9.30155C99.7134 8.65979 99.2666 8.33892 98.373 8.33892C97.4947 8.33892 96.8476 8.77577 96.4316 9.64948L92.6298 8.87242C93.5928 6.69974 95.7113 5.6134 98.9854 5.6134C100.642 5.6134 101.944 5.90335 102.891 6.48325C103.839 7.05541 104.313 7.90206 104.313 9.0232C104.313 9.52577 104.035 11.3041 103.481 14.3582C103.273 15.4948 103.169 16.2487 103.169 16.6198C103.169 16.9214 103.207 17.2655 103.284 17.6521ZM99.1703 12.0851C97.0518 12.2474 95.9925 12.9278 95.9925 14.1263C95.9925 14.7835 96.3353 15.1121 97.021 15.1121C97.4601 15.1121 97.8992 14.9149 98.3383 14.5206C98.7774 14.1263 99.0547 13.3144 99.1703 12.0851ZM97.3098 2.67912L102.637 0L103.434 2.18041L97.9107 4.18686L97.3098 2.67912Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M107.317 5.93814H111.385L111.073 7.84021C111.388 7.05928 111.905 6.49098 112.621 6.13531C113.337 5.77191 114.092 5.59021 114.886 5.59021C115.88 5.59021 116.685 5.85309 117.301 6.37887C117.917 6.90464 118.226 7.59665 118.226 8.4549C118.226 8.78737 118.187 9.18557 118.11 9.64948L116.77 17.6521H112.332L113.511 10.6121C113.588 10.1637 113.626 9.86598 113.626 9.71907C113.626 9.06186 113.28 8.73325 112.586 8.73325C111.654 8.73325 111.084 9.35954 110.876 10.6121L109.697 17.6521H105.364L107.317 5.93814Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M127.158 13.0941L130.682 13.9639C130.051 15.4794 129.223 16.5309 128.198 17.1186C127.173 17.6985 126.114 17.9884 125.02 17.9884C123.41 17.9884 122.093 17.4781 121.068 16.4575C120.051 15.4291 119.543 14.1108 119.543 12.5026C119.543 10.6701 120.144 9.05026 121.346 7.64304C122.555 6.23582 124.154 5.53222 126.141 5.53222C127.582 5.53222 128.803 5.96907 129.804 6.84278C130.806 7.70876 131.306 8.82603 131.306 10.1946L127.343 10.6585C127.343 9.39046 126.915 8.75644 126.06 8.75644C125.328 8.75644 124.808 9.27448 124.5 10.3106C124.192 11.3466 124.038 12.2513 124.038 13.0245C124.038 14.1688 124.469 14.741 125.332 14.741C126.18 14.741 126.788 14.192 127.158 13.0941Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M138.76 1.82088L137.835 7.37629C138.691 6.1933 139.835 5.6018 141.267 5.6018C142.092 5.6018 142.847 5.8299 143.532 6.28608C144.218 6.74227 144.561 7.53866 144.561 8.67526C144.561 9.10052 144.511 9.63015 144.411 10.2642L143.174 17.6521H138.737L139.892 10.7281C139.992 10.1482 140.043 9.80799 140.043 9.70747C140.043 9.08119 139.665 8.76804 138.91 8.76804C138.587 8.76804 138.313 8.84923 138.09 9.0116C137.866 9.16624 137.697 9.34794 137.581 9.5567C137.473 9.76546 137.369 10.1559 137.269 10.7281L136.125 17.6521H131.688L134.322 1.82088H138.76Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M158.451 12.6649H150.408C150.369 12.982 150.35 13.2178 150.35 13.3724C150.35 13.8827 150.512 14.2809 150.835 14.567C151.167 14.8531 151.583 14.9961 152.083 14.9961C153.031 14.9961 153.701 14.5052 154.094 13.5232L158.023 14.1611C157.515 15.3054 156.69 16.2332 155.55 16.9446C154.418 17.6482 153.158 18 151.771 18C150.03 18 148.617 17.5052 147.531 16.5155C146.452 15.518 145.913 14.1611 145.913 12.4446C145.913 10.5812 146.552 8.98454 147.831 7.65464C149.11 6.31701 150.782 5.6482 152.846 5.6482C154.595 5.6482 155.985 6.16624 157.018 7.20232C158.058 8.2384 158.578 9.61469 158.578 11.3312C158.578 11.7178 158.535 12.1624 158.451 12.6649ZM154.244 10.4034C154.283 10.1637 154.302 9.97809 154.302 9.84665C154.302 9.42912 154.171 9.06572 153.909 8.75644C153.655 8.44716 153.297 8.29253 152.835 8.29253C152.342 8.29253 151.895 8.48196 151.494 8.86082C151.094 9.23196 150.847 9.74613 150.755 10.4034H154.244Z"
                fill={props.fill || 'white'}
            />
            <path
                d="M169.532 14.7178L169.047 17.6521H158.601L159.04 15.0193L164.841 8.77964H160.473L160.947 5.93814H171L170.619 8.19974L164.506 14.7178H169.532Z"
                fill={props.fill || 'white'}
            />
        </svg>
    );
};

export default RubenSanchezIcon;
