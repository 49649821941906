export const FormValidator = (baseObject: any, newObject: any, dic: any, notifyError: (text: string) => void, execptions: string[] = []) => {
    let pass = true;
    const emptyFields: string[] = [];

    Object.keys(baseObject).forEach((key) => {
        if (key != 'id' && !execptions.includes(key))
            if (baseObject[key] == newObject[key]) {
                pass = false;
                emptyFields.push(key);
            }
    });

    emptyFields.forEach((field: string) => {
        notifyError(`Debe ingresar un/a ${dic[field]}`);
    });

    return pass;
};
