import React from 'react';
import IconProps from './IconProps.type';

const SaveIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 16V10H14V16H16V4.828L13.172 2H2V16H4ZM1 0H14L18 4V17C18 17.2652 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H1C0.734784 18 0.48043 17.8946 0.292893 17.7071C0.105357 17.5196 0 17.2652 0 17V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0ZM6 12V16H12V12H6Z"
                fill={props.fill || 'white'}
            />
        </svg>
    );
};

export default SaveIcon;
