import Axios from 'axios';

import * as msal from '@azure/msal-browser';
import { Configurations } from '../EnvSettings';
import { currentUserStorage } from '../../helpers';

let authority = Configurations.AD_AUTHORITY;
const tenantId = Configurations.AD_TENANT_ID;
const scope = Configurations.AD_SCOPE;
authority = authority?.replace('{your_tenant_id}', `${tenantId}`);

const msalConfig: msal.Configuration = {
    auth: {
        clientId: `${Configurations.AD_CLIENT_ID}`,
        authority: authority,
    },
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

const isAnonymousRoute = () => {
    return false;
};

const CallForLoginOrHandleRedirect = (afterLoginSucceded: any) => {
    if (isAnonymousRoute()) return;
    msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
            if (tokenResponse !== null) {
                Axios.interceptors.request.use(
                    function (request) {
                        const token = tokenResponse.accessToken;
                        if (token !== null) {
                            request.headers.common = request.headers.common || undefined;
                            request.headers['Authorization'] = `Bearer ${token}`;
                            Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                        }
                        return request;
                    },
                    function (error) {
                        return Promise.reject(error);
                    },
                );
                afterLoginSucceded.call(undefined, tokenResponse);
            } else {
                getSilentToken().then((tokenResponse) => {
                    afterLoginSucceded(tokenResponse);
                });
            }
        })
        .catch((error) => {
            throw error;
        });
};

function getSilentToken() {
    const userStorage = currentUserStorage.getString();
    const currentUser = userStorage ? JSON.parse(userStorage) : {};
    const currentAccount = msalInstance.getAccountByUsername(currentUser.email) || undefined;
    const silentRequest: msal.SilentRequest = {
        scopes: [`${scope}`, 'Mail.Read'],
        account: currentAccount,
        forceRefresh: false,
    };
    const promise = msalInstance.acquireTokenSilent(silentRequest).catch((error) => {
        if (
            error instanceof msal.InteractionRequiredAuthError ||
            error.errorCode === 'no_account_error' ||
            error.errorCode === 'no_account_in_silent_request'
        ) {
            return msalInstance.acquireTokenRedirect(silentRequest);
        } else {
            console.log('Sesión expirada');
        }
    });
    return promise;
}

export default CallForLoginOrHandleRedirect;
export { getSilentToken, msalInstance, isAnonymousRoute };
