/* eslint-disable @typescript-eslint/no-empty-function */

import React, { createContext, useState } from 'react';
import { User } from '../models';

interface AuthContextProps {
    user: User | undefined;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
}

export const AuthContext = createContext<AuthContextProps>({
    user: undefined,
    setUser: () => {},
});

interface AuthProviderProps {
    children: JSX.Element;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User>();

    return <AuthContext.Provider value={{ user, setUser }}>{children}</AuthContext.Provider>;
};
