import React from 'react';
import ClipIcon from '../../assets/icons/ClipIcon';
import RubenSanchezImg from '../../assets/images/RubenSanchez.jpg';
import { ZoneEnum } from '../../models';
import SocialLinks from '../layout/SocialLinks';

const ProfileCard: React.FC = () => {
    return (
        <article className="profile-card">
            <div className="clip-container">
                <div className="hidder"></div>
                <ClipIcon className="medium-icon" />
            </div>
            <div className="image-container">
                <img src={RubenSanchezImg} alt="Rubén Sánchez" />
            </div>
            <div className="title">Rubén Sánchez</div>
            <div className="description">
                <p>
                    Bienvenidos a este espacio creado para comprender por dónde camina Puerto Rico. Les invito a suscribirse y a compartir esta página
                    con sus familiares y amigos.
                </p>
                <br />
                <p>Siempre de frente,</p>
                <p>Rubén</p>
            </div>
            <SocialLinks zone={ZoneEnum.Card} />
        </article>
    );
};

export default ProfileCard;
