import { useEffect } from 'react';

export const useAsync = (asyncFunc: () => Promise<unknown>, successFunc: (p: any) => unknown, dependencies: any = [], condition = true) => {
    useEffect(() => {
        let isActive = true;

        if (condition === true) {
            asyncFunc().then((result: any) => {
                if (isActive) successFunc(result?.data);
            });
        }
        return () => {
            isActive = false;
        };
    }, dependencies);
};
