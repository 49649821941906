import React, { useContext, useRef } from 'react';
import LoadingIcons from 'react-loading-icons';
import { NotifyContext } from '../../context/NotifyContext';
import { useFetchAndLoad } from '../../hooks';
import { suscribeMail } from '../../services/Suscriber.service';

const Newsletter: React.FC = () => {
    const { loading, callEndpoint } = useFetchAndLoad();
    const { notifySuccess, notifyError } = useContext(NotifyContext);
    const formRef = useRef<HTMLFormElement>(null);

    const submit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const mailInput = e.currentTarget.email;

        try {
            const result = await callEndpoint(suscribeMail(mailInput.value));
            if (result) {
                notifySuccess('¡Gracias por suscribirse!');
                mailInput.value = '';
            }
        } catch (e) {
            notifyError('Hubo un error al gsuscribirse');
            console.log(e);
        }
    };

    return (
        <article className="newsletter-section">
            <form id="newsletter" ref={formRef} onSubmit={submit}>
                <h3 className="title">Entérate</h3>
                <div className="description">Pon tu correo debajo y recibe todas los noticias de tu interés.</div>
                <input id="email" className="email-input" type="email" placeholder="Correo:" />
                <button className="suscribe-button" type="submit">
                    {!loading ? <span>Suscríbete</span> : <LoadingIcons.Oval width="5rem" height="1.5rem" />}
                </button>
            </form>
        </article>
    );
};

export default Newsletter;
