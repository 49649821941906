import React, { useContext, useState } from 'react';
import SidebarAds from '../../components/Ads/SidebarAds';
import { Ads, AdsDimensions, AdsTypeEnum, News } from '../../models';
import ShareIcon from '../../assets/icons/ShareIcon';
import BottomAdd from '../../components/Ads/BottomAds';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getNewsById, removeNews } from '../../services/News.service';
import { useAsync, useAuth } from '../../hooks';
import { useFetchAndLoad } from '../../hooks/useFetchAndLoad.hook';
import ImageContainer from '../../components/Templates/ImageContainer';
import { DateTimeAgo } from '../../helpers';
import EditIcon from '../../assets/icons/EditIcon';
import RemoveIcon from '../../assets/icons/RemoveIcon';
import { NotifyContext } from '../../context/NotifyContext';
import LoadingComponent from '../../components/Templates/LoadingComponent';
import AdsArticle from '../../components/Ads/AdsArticle';
import reactStringReplace from 'react-string-replace';

const NewsDetailPage: React.FC = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { notifyError, notifySuccess } = useContext(NotifyContext);
    const { loading, callEndpoint } = useFetchAndLoad();
    const { loading: removeLoading, callEndpoint: removeCallEndpoint } = useFetchAndLoad();
    const [news, setNews] = useState<News>();

    const getNewsApiCall = async () => await callEndpoint(getNewsById(pathname.split('/').slice(-1).toString()));

    const adaptData = (backData: any) => {
        if (backData) setNews(backData);
    };

    const formatVideo = (videoUrl: string) => {
        return videoUrl.replace('watch?v=', 'embed/').replace('.com', '-nocookie.com');
    };

    const handleRemove = async () => {
        if (!removeLoading && news) {
            if (confirm('¿Seguro que desea eliminar esta noticia?')) {
                try {
                    const result = await removeCallEndpoint(removeNews(news.id));
                    if (result) {
                        notifySuccess('La noticia se ha eliminado correctamente');
                        if (pathname.includes('news')) navigate('/');
                        else navigate(0);
                    }
                } catch (e) {
                    notifyError('Ha ocurrido un error');
                    console.log(e);
                }
            }
        }
    };

    const descriptionReader = (desc: string) => {
        let originalDescription: any = desc;

        Object.keys(AdsDimensions).forEach((dimension) => {
            const type: string = AdsTypeEnum[Number(dimension)];
            const replaceText = `<Anuncio:${type}>`;

            const bannerType: AdsTypeEnum = dimension as unknown as AdsTypeEnum;

            const ads: Ads = {
                id: '',
                img: '',
                redirectLink: '',
                bannerType: bannerType,
            };

            originalDescription = reactStringReplace(originalDescription, replaceText, () => <AdsArticle {...ads} key={dimension} />);
        });

        return originalDescription;
    };

    useAsync(getNewsApiCall, adaptData, []);

    return (
        <main className="news-detail-page">
            <div className="page-container">
                <div className="content-container">
                    <div className="content">
                        {user && news != null && (
                            <div className="action-buttons">
                                <Link to={`/edit/news/${news.id}`} className="edit-container">
                                    <EditIcon className="tiny-static-plus-icon" />
                                    <span>Editar</span>
                                </Link>
                                <div onClick={handleRemove} className="remove-container">
                                    <RemoveIcon className="tiny-static-plus-icon" />
                                    <span>Eliminar</span>
                                </div>
                            </div>
                        )}
                        {!loading ? (
                            news != null && (
                                <article className="news-detail">
                                    <ImageContainer img={news.img} />
                                    <div className="share-date-container">
                                        <ShareIcon className="small-less-icon" />
                                        <div className="date">{DateTimeAgo(news.date)}</div>
                                    </div>
                                    <h2 className="title">{news.title}</h2>
                                    <div className="description">{descriptionReader(news.description)}</div>
                                    {news.video && (
                                        <div className="video">
                                            <iframe src={formatVideo(news.video)}></iframe>
                                        </div>
                                    )}
                                </article>
                            )
                        ) : (
                            <LoadingComponent />
                        )}
                        <BottomAdd />
                    </div>
                    <SidebarAds />
                </div>
            </div>
        </main>
    );
};

export default NewsDetailPage;
