import React from 'react';
import FacebookIcon from '../../assets/icons/FacebookIcon';
import InstagramIcon from '../../assets/icons/InstagramIcon';
import YoutubeIcon from '../../assets/icons/YoutubeIcon';
import { ZoneEnum } from '../../models';

interface SocialLinksProps {
    zone: ZoneEnum;
}

const SocialLinks: React.FC<SocialLinksProps> = ({ zone }) => {
    const iconClass = (): string => {
        switch (zone) {
            case ZoneEnum.Header:
                return 'small-icon';

            case ZoneEnum.Card:
                return 'small-static-icon';

            case ZoneEnum.Footer:
                return 'small-less-icon';
        }
    };

    const getYoutubeClass = (sourceClass: string): string => {
        switch (sourceClass) {
            case 'small-icon':
                return 'medium-icon';

            case 'small-static-icon':
                return 'medium-static-icon';

            case 'small-less-icon':
                return 'medium-less-icon';

            default:
                return '';
        }
    };

    const fillColor = (): string => {
        switch (zone) {
            case ZoneEnum.Card:
                return 'gray';

            case ZoneEnum.Header:
            case ZoneEnum.Footer:
                return 'white';
        }
    };

    return (
        <nav className="social-links">
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/PresentadorRubenSanchez" className="social-link">
                <FacebookIcon className={iconClass()} fill={fillColor()} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/oficialrubentv/" className="social-link">
                <InstagramIcon className={iconClass()} fill={fillColor()} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@rubensanchez7804" className="social-link">
                <YoutubeIcon className={getYoutubeClass(iconClass())} fill={fillColor()} />
            </a>
        </nav>
    );
};

export default SocialLinks;
