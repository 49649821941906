import React, { useState } from 'react';
import { News } from '../../models';
import { Link } from 'react-router-dom';
import { useAsync, useFetchAndLoad } from '../../hooks';
import { getMainNew } from '../../services/News.service';
import ImageContainer from '../Templates/ImageContainer';
import { mainNewsStorage } from '../../helpers';
import LoadingComponent from '../Templates/LoadingComponent';

const MainNewsArticle: React.FC = () => {
    const { loading, callEndpoint } = useFetchAndLoad();
    const [mainNews, setMainNews] = useState<News>();
    const getNewsApiCall = async () => await callEndpoint(getMainNew());

    const adaptMainNew = (data: any) => {
        if (data) {
            setMainNews(data);
            mainNewsStorage.set(data.id);
        } else {
            mainNewsStorage.remove();
        }
    };

    useAsync(getNewsApiCall, adaptMainNew, []);

    if (loading) return <LoadingComponent />;

    if (mainNews)
        return (
            <article className="main-news-article">
                <Link className="main-news" to={`/news/${mainNews?.id}`}>
                    {mainNews?.img && <ImageContainer img={mainNews.img} />}
                    <div className="title">
                        <span>{mainNews?.title}</span>
                    </div>
                </Link>
            </article>
        );
    else return <article className="main-news-article"></article>;
};

export default MainNewsArticle;
