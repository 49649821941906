import { User } from './../models/User/User.model';
const tokenStorageName = 'token';
const currentUserStorageName = 'currentUser';
const mainNewsStorageName = 'mainNews';

export const tokenStorage = {
    set: function (token: string) {
        localStorage.setItem(tokenStorageName, token);
    },
    get: function () {
        return localStorage.getItem(tokenStorageName);
    },
    remove: function () {
        localStorage.removeItem(tokenStorageName);
    },
};

export const currentUserStorage = {
    set: function (user: User) {
        localStorage.setItem(currentUserStorageName, JSON.stringify(user));
    },
    get: function () {
        return JSON.parse(`${localStorage.getItem(currentUserStorageName)}`);
    },
    getString: function () {
        return localStorage.getItem(currentUserStorageName);
    },
    remove: function () {
        localStorage.removeItem(currentUserStorageName);
    },
};

export const mainNewsStorage = {
    set: function (newsId: string) {
        localStorage.setItem(mainNewsStorageName, newsId);
    },
    get: function () {
        return localStorage.getItem(mainNewsStorageName);
    },
    remove: function () {
        localStorage.removeItem(mainNewsStorageName);
    },
};
