import React from 'react';
import IconProps from './IconProps.type';

const FacebookIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 0C4.477 0 0 4.477 0 10C0 14.991 3.657 19.128 8.438 19.879V12.89H5.898V10H8.438V7.797C8.438 5.291 9.93 3.907 12.215 3.907C13.309 3.907 14.453 4.102 14.453 4.102V6.562H13.193C11.95 6.562 11.563 7.333 11.563 8.124V10H14.336L13.893 12.89H11.563V19.879C16.343 19.129 20 14.99 20 10C20 4.477 15.523 0 10 0Z"
                fill={props.fill || 'white'}
            />
        </svg>
    );
};

export default FacebookIcon;
