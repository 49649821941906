import React, { useState } from 'react';
import { AspectRatioCalculator } from '../../helpers';
import { useAsync, useFetchAndLoad } from '../../hooks';
import { Ads, AdsTypeEnum } from '../../models';
import { getAdsByBannerType } from '../../services';
import ImageContainer from '../Templates/ImageContainer';

const newAds: Ads = {
    id: '',
    img: '',
    redirectLink: '',
    bannerType: 0,
};

const BottomAds: React.FC = () => {
    const aspectRatio = AspectRatioCalculator(AdsTypeEnum.LeaderBoard);
    const { loading, callEndpoint } = useFetchAndLoad();
    const [data, setData] = useState<Ads>(newAds);

    const getAdsByBannerTypeApiCAll = async () => await callEndpoint(getAdsByBannerType(AdsTypeEnum.LeaderBoard));

    const adaptData = (backData: any) => {
        const adsData = backData[0];
        if (adsData) setData(adsData);
    };

    useAsync(getAdsByBannerTypeApiCAll, adaptData, []);

    return (
        <article className={`ads-bottom ${data.bannerType}`}>
            <a target="_blank" rel="noopener noreferrer" href={data.redirectLink || '#'}>
                <ImageContainer style={{ aspectRatio: aspectRatio }} img={data.img} />
            </a>
        </article>
    );
};

export default BottomAds;
