import React, { useContext, useRef, useState } from 'react';
import EditIcon from '../../assets/icons/EditIcon';
import SaveIcon from '../../assets/icons/SaveIcon';
import { AspectRatioCalculator, FormValidator } from '../../helpers';
import { Ads, AdsDimensions, AdsTypeEnum, FormAds } from '../../models';
import ImageContainer from '../Templates/ImageContainer';
import ImageFieldContainer from '../../components/Templates/ImageFieldContainer';
import { NotifyContext } from '../../context/NotifyContext';
import AdsDictionary from '../../dictionaries/AdsDictionary.json';
import { ImageUploader } from '../../helpers/ImageUploader.utility';
import { useFetchAndLoad } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { createAds, editAds, removeAds } from '../../services';
import LoadingIcons from 'react-loading-icons';
import RemoveIcon from '../../assets/icons/RemoveIcon';

const adsEntityNames = AdsDictionary.ln_es.entity;

const newAds: Ads = {
    id: '',
    img: '',
    redirectLink: '',
    bannerType: AdsTypeEnum.MediumRectangle,
};

interface FormAdsProps {
    ads?: Ads;
    setShowAdd?: React.Dispatch<React.SetStateAction<boolean>>;
}

const getDimensionsOptions = () => {
    return Object.keys(AdsDimensions).map((dimension) => {
        const type = AdsTypeEnum[Number(dimension)];
        return (
            <option value={dimension} key={dimension}>
                {type}
            </option>
        );
    });
};

const FormAdsArticle: React.FC<FormAdsProps> = ({ ads, setShowAdd }) => {
    const { notifySuccess, notifyError } = useContext(NotifyContext);
    const { loading: loadedRemove, callEndpoint: removeCallEndpoint } = useFetchAndLoad();
    const { loading: loadingImage, callEndpoint: imageCallEndpoint } = useFetchAndLoad();
    const { loading, callEndpoint: formCallEndpoint } = useFetchAndLoad();
    const aspectRatio = AspectRatioCalculator(ads?.bannerType || newAds.bannerType);
    const [data, setData] = useState<Ads>(ads || newAds);
    const [showEdit, setShowEdit] = useState<boolean>(ads ? false : true);
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);

    const close = () => (setShowAdd ? setShowAdd(false) : setShowEdit(false));

    const handleRemove = async () => {
        if (!loading && ads) {
            if (confirm('¿Seguro que desea eliminar este anuncio?')) {
                try {
                    const result = await removeCallEndpoint(removeAds(ads.id));
                    if (result) {
                        notifySuccess('El anuncio se ha eliminado correctamente');
                        navigate(0);
                    }
                } catch (e) {
                    notifyError('Ha ocurrido un error');
                    console.log(e);
                }
            }
        }
    };

    const handleSubmit = async () => {
        if (formRef.current) {
            const imageName: string = formRef.current.image.value;
            const imageFile = formRef.current.image.files[0];

            const body = {
                img: imageName,
                url: formRef.current.video.value,
                bannerType: formRef.current.dimension.value,
            };

            if (FormValidator(newAds, body, adsEntityNames, notifyError, ['bannerType'])) {
                let imageId: string | null = data.img;

                if (imageName != '' && (!imageName.includes(imageId) || imageId == '')) {
                    imageId = await ImageUploader(imageFile, notifyError, imageCallEndpoint);
                }

                if (imageId) {
                    const sendNews: FormAds = {
                        imageId: imageId,
                        bannerType: Number(body.bannerType),
                        redirectLink: body.url,
                    };
                    saveAds(sendNews);
                } else {
                    notifyError('Hubo un error al guardar la imagen');
                }
            }
        }
    };

    const saveAds = async (adsData: FormAds) => {
        let result: any;
        try {
            if (ads) result = await formCallEndpoint(editAds(data.id, adsData));
            else result = await formCallEndpoint(createAds(adsData));

            if (result) {
                navigate(0);
                notifySuccess('El anuncio se ha guardado de forma correcta');
            }
        } catch (e) {
            notifyError('Hubo un error al guardar el anuncio');
            console.log(e);
        }
    };

    if (showEdit)
        return (
            <form className="ads-item edit" ref={formRef}>
                <div className="image">
                    <ImageFieldContainer style={{ aspectRatio: aspectRatio }} img={data.img} />
                </div>
                <div className="url">
                    <div className="video-field">
                        <label htmlFor="video">Enlace</label>
                        <input className="form-field" type="text" id="video" defaultValue={data.redirectLink} />
                    </div>
                </div>
                <div className="type">
                    <div className="dimension-field">
                        <label htmlFor="dimension">Dimensión</label>
                        <select id="dimension" className="form-field selector-field" defaultValue={data.bannerType}>
                            {getDimensionsOptions()}
                        </select>
                    </div>
                </div>
                <div className="operations">
                    {!loadingImage && !loading ? (
                        <>
                            <SaveIcon fill="#9a0018" onClick={handleSubmit} className="small-less-icon" />
                            <div className="cancel" onClick={() => close()}>
                                Cancelar
                            </div>
                        </>
                    ) : (
                        <LoadingIcons.Oval stroke="#9a0018" width="2.5rem" height="2.5rem" />
                    )}
                </div>
            </form>
        );
    else
        return (
            <div className="ads-item">
                <div className="image">
                    <ImageContainer style={{ aspectRatio: aspectRatio }} img={data.img} />
                </div>
                <div className="url">{data.redirectLink ? <a href={data.redirectLink}>{data.redirectLink}</a> : '-'}</div>
                <div className="type">{AdsDimensions[data.bannerType].label}</div>
                <div className="operations">
                    <EditIcon onClick={() => setShowEdit(true)} className="small-less-icon" />
                    {!loadedRemove ? (
                        <RemoveIcon onClick={handleRemove} className="small-less-icon" />
                    ) : (
                        <LoadingIcons.Oval stroke="#9a0018" width="2rem" height="2rem" />
                    )}
                </div>
            </div>
        );
};

export default FormAdsArticle;
