import React, { useState } from 'react';
import { AspectRatioCalculator } from '../../helpers';
import { useAsync, useFetchAndLoad } from '../../hooks';
import { Ads } from '../../models';
import { getAdsByBannerType } from '../../services';
import ImageContainer from '../Templates/ImageContainer';

const AdsArticle: React.FC<Ads> = (ads) => {
    const aspectRatio = AspectRatioCalculator(ads.bannerType);
    const { loading, callEndpoint } = useFetchAndLoad();
    const [data, setData] = useState<Ads>(ads);

    const getAdsByBannerTypeApiCAll = async () => await callEndpoint(getAdsByBannerType(ads.bannerType));

    const adaptData = (backData: any) => {
        const adsData: Ads = backData[0];
        if (adsData) setData(adsData);
    };

    useAsync(getAdsByBannerTypeApiCAll, adaptData, []);

    return (
        <article className={`ads-article type-${ads.bannerType}`}>
            <a target="_blank" rel="noopener noreferrer" href={data.redirectLink}>
                <ImageContainer style={{ aspectRatio: aspectRatio }} img={data.img} />
            </a>
        </article>
    );
};

export default AdsArticle;
