import api from '../api';
import { loadAbort } from '../helpers';

export const getImageById = (id: string) => {
    const controller = loadAbort();
    return { call: api.get(`api/Files/download/${id}`, { signal: controller.signal, responseType: 'blob' }), controller };
};

export const uploadImage = (file: File) => {
    const formData = new FormData();
    formData.append('file', file);
    const controller = loadAbort();
    return {
        call: api.post('api/Files/upload', formData, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
            signal: controller.signal,
        }),
        controller,
    };
};
