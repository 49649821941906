import React, { useState } from 'react';
import BottomAdd from '../components/Ads/BottomAds';
import MainSidebar from '../components/layout/MainSidebar';
import Banner from '../assets/images/Banner.jpg';
import MainNewsArticle from '../components/News/MainNewsArticle';
import { News } from '../models';
import NewsArticle from '../components/News/NewsArticle';
import { useFetchAndLoad } from '../hooks/useFetchAndLoad.hook';
import { useAuth } from '../hooks';
import { getAllNews } from '../services/News.service';
import AddIcon from '../assets/icons/AddIcon';
import { Link } from 'react-router-dom';
import LoadingComponent from '../components/Templates/LoadingComponent';
import ListPagination from '../components/Templates/ListPagination';

const HomePage: React.FC = () => {
    const { user } = useAuth();
    const { loading, callEndpoint } = useFetchAndLoad();
    const [allNews, setAllNews] = useState<News[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const getNewsApiCall = async () => await callEndpoint(getAllNews(10, pageNumber));

    const adaptAllNews = (data: any) => {
        if (data.items) setAllNews(data.items);
    };

    return (
        <main className="home-page">
            <MainNewsArticle />
            <div className="page-container">
                <MainSidebar />
                <div className="content-container">
                    <img className="banner-img" src={Banner} alt="Ruben Sanchez Banner" />
                    <section className="news-container">
                        <div className="content-title">
                            <h2 className="title">Noticias</h2>
                            {user && (
                                <Link to="/create/news" className="add-container">
                                    <AddIcon className="tiny-static-plus-icon" />
                                    <span>Nueva noticia</span>
                                </Link>
                            )}
                        </div>
                        <section className="news-list">
                            {!loading ? (
                                allNews.length > 0 ? (
                                    allNews.map((news, i) => (
                                        <React.Fragment key={i}>
                                            <NewsArticle {...news} />
                                            {i + 1 != allNews.length && (i + 1) % 3 == 0 && <BottomAdd />}
                                        </React.Fragment>
                                    ))
                                ) : (
                                    <h4 className="empty">No se encontro ninguna noticia</h4>
                                )
                            ) : (
                                <LoadingComponent />
                            )}
                            <ListPagination
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                getApiCall={getNewsApiCall}
                                adaptData={adaptAllNews}
                            />
                        </section>
                    </section>
                </div>
            </div>
            <BottomAdd />
        </main>
    );
};

export default HomePage;
