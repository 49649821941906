import React, { useEffect, useRef, useState } from 'react';
import { Ads, AdsTypeEnum, AdsDimensions, AdsDimension } from '../../models';
import AdsArticle from './AdsArticle';

const getActualHeight = (parentWidth: number, dimensions: AdsDimension) => {
    return (parentWidth * dimensions.height) / dimensions.width;
};

const getAdsCapcity = (height: number, width: number) => {
    let parentHeight = height.valueOf();
    const parentWidth = width.valueOf();

    const mediumRectangleHeight = getActualHeight(parentWidth, AdsDimensions[AdsTypeEnum.MediumRectangle]);
    const largeRectangleHeight = getActualHeight(parentWidth, AdsDimensions[AdsTypeEnum.LargeRectangle]);
    const skyscrapperHeight = getActualHeight(parentWidth, AdsDimensions[AdsTypeEnum.Skyscrapper]);

    const firstLevel = mediumRectangleHeight + 50 + skyscrapperHeight + 50 + largeRectangleHeight + 50;
    const secondLevel = mediumRectangleHeight + 50 + skyscrapperHeight + 50;
    const thirdLevel = largeRectangleHeight + 50 + mediumRectangleHeight + 50;
    const fourthLevel = largeRectangleHeight + 50;

    const adsList: Ads[] = [];

    if (parentHeight > firstLevel) {
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.MediumRectangle,
        });
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.Skyscrapper,
        });
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.LargeRectangle,
        });
        parentHeight -= firstLevel;
    }

    if (parentHeight > secondLevel) {
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.MediumRectangle,
        });
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.Skyscrapper,
        });
        parentHeight -= secondLevel;
    }

    if (parentHeight > thirdLevel) {
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.MediumRectangle,
        });
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.LargeRectangle,
        });
        parentHeight -= thirdLevel;
    }

    if (parentHeight > fourthLevel) {
        adsList.push({
            id: '',
            img: '',
            redirectLink: '',
            bannerType: AdsTypeEnum.LargeRectangle,
        });

        parentHeight -= fourthLevel;
    }

    return adsList;
};

const SidebarAds: React.FC = () => {
    const [ads, setAds] = useState<Ads[]>([]);
    const sideBarRef = useRef<HTMLElement>(null);

    const handleElementResized = () => {
        if (sideBarRef.current) {
            const { height, width } = sideBarRef.current.getBoundingClientRect();
            setAds(getAdsCapcity(height, width));
        }
    };

    const resizeObserver = new ResizeObserver(handleElementResized);

    useEffect(() => {
        if (sideBarRef.current) resizeObserver.observe(sideBarRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <section ref={sideBarRef} className="ads-sidebar">
            <div className="ads-container">
                {ads.map((ad, i) => (
                    <AdsArticle {...ad} key={i} />
                ))}
            </div>
        </section>
    );
};

export default SidebarAds;
