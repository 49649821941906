import React, { useEffect, useRef, useState } from 'react';

type AutoSizeTextareaProps = {
    className: string;
    id: string;
    text: string;
};

const AutoSizeTextarea: React.FC<AutoSizeTextareaProps> = ({ className, id, text }) => {
    const [value, setValue] = useState<string>(text);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = '0px';
            const scrollHeight = textAreaRef.current.scrollHeight;
            textAreaRef.current.style.height = scrollHeight + 1.5 + 'px';
        }
    }, [textAreaRef, value]);

    useEffect(() => setValue(text), [text]);

    return <textarea ref={textAreaRef} className={className} id={id} onChange={handleChange} value={value} rows={1} />;
};

export default AutoSizeTextarea;
