import api from '../api';
import { loadAbort } from '../helpers';
import { FormCalendarEvent } from '../models';

export const getAllCalendarEvents = (pageSize = 10, pageNumber = 1) => {
    const controller = loadAbort();
    return {
        call: api.get('api/Event', {
            headers: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
            signal: controller.signal,
        }),
        controller,
    };
};

export const getCalendarEventById = (id: string) => {
    const controller = loadAbort();
    return { call: api.get(`api/Event/${id}`, { signal: controller.signal }), controller };
};

export const createCalendarEvent = (entity: FormCalendarEvent) => {
    const controller = loadAbort();
    return {
        call: api.post('api/Event', entity, { signal: controller.signal }),
        controller,
    };
};

export const editCalendarEvent = (id: string, entity: FormCalendarEvent) => {
    const controller = loadAbort();
    return {
        call: api.patch(`api/Event/${id}`, entity, { signal: controller.signal }),
        controller,
    };
};

export const removeCalendarEvent = (id: string) => {
    const controller = loadAbort();
    return {
        call: api.delete(`api/Event/${id}`, { signal: controller.signal }),
        controller,
    };
};
