import { User } from './../models/User/User.model';
import { currentUserStorage } from '../helpers/LocalStorage.utility';
import { useEffect } from 'react';
import { useUser } from './';

export const useAuth = () => {
    const { user, addUser, removeUser } = useUser();

    useEffect(() => {
        const userData = currentUserStorage.get();
        if (userData) addUser(userData);
    }, []);

    const login = (userData: User) => {
        addUser(userData);
    };

    const logout = () => {
        removeUser();
    };

    return { user, login, logout };
};
