import { axiosCallProps } from '../hooks';
import { uploadImage } from '../services';

export const ImageUploader = async (
    image: File,
    notifyError: (text: string) => void,
    imageCallEndpoint: (axiosCall: axiosCallProps) => Promise<any>,
): Promise<string | null> => {
    try {
        const result = await imageCallEndpoint(uploadImage(image));
        if (result) return result.data.id;
        else return null;
    } catch (e) {
        notifyError('Hubo un error al guardar la imagen');
        console.log(e);
        return null;
    }
};
