import api from '../api';
import { loadAbort } from '../helpers';
import { FormNews } from '../models';

export const getAllNews = (pageSize = 10, pageNumber = 1) => {
    const controller = loadAbort();
    return {
        call: api.get('api/New', {
            headers: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
            signal: controller.signal,
        }),
        controller,
    };
};

export const getMainNew = () => {
    const controller = loadAbort();
    return {
        call: api.get('api/New/Principal', { signal: controller.signal }),
        controller,
    };
};

export const getNewsById = (id: string) => {
    const controller = loadAbort();
    return {
        call: api.get(`api/New/${id}`, { signal: controller.signal }),
        controller,
    };
};

export const getNewsByName = (name: string, pageSize = 10, pageNumber = 1) => {
    const controller = loadAbort();
    return {
        call: api.get(`api/New/Search/${name}`, {
            headers: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
            signal: controller.signal,
        }),
        controller,
    };
};

export const createNews = (entity: FormNews) => {
    const controller = loadAbort();
    return {
        call: api.post('api/New', entity, { signal: controller.signal }),
        controller,
    };
};

export const editNews = (id: string, entity: FormNews) => {
    const controller = loadAbort();
    return {
        call: api.patch(`api/New/${id}`, entity, { signal: controller.signal }),
        controller,
    };
};

export const removeNews = (id: string) => {
    const controller = loadAbort();
    return {
        call: api.delete(`api/New/${id}`, { signal: controller.signal }),
        controller,
    };
};
