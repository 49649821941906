import React from 'react';
import IconProps from './IconProps.type';

const AddIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.3333 3.2H3.22222V28.8H25.7777V9.6H19.3333V3.2ZM0 1.5872C0 0.7104 0.720166 0 1.6095 0H20.9444L29 8V30.3888C29.0014 30.5989 28.9612 30.8073 28.8816 31.002C28.8021 31.1966 28.6846 31.3738 28.5361 31.5235C28.3875 31.6731 28.2107 31.7922 28.0158 31.8739C27.8209 31.9557 27.6117 31.9985 27.4001 32H1.59983C1.17672 31.9971 0.771749 31.829 0.472406 31.532C0.173062 31.235 0.00337471 30.833 0 30.4128V1.5872ZM12.8889 14.4V9.6H16.1111V14.4H20.9444V17.6H16.1111V22.4H12.8889V17.6H8.05554V14.4H12.8889Z"
                fill={props.fill || '#9a0018'}
            />
        </svg>
    );
};

export default AddIcon;
