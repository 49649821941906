import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.scss';
import App from './App';
import { AuthProvider } from './context/AuthContext';
import { NotifyProvider } from './context/NotifyContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <NotifyProvider>
            <AuthProvider>
                <App />
            </AuthProvider>
        </NotifyProvider>
    </React.StrictMode>,
);
