import moment from 'moment';
import 'moment/locale/es';

export const DateTimeAgo = (date: string): string => {
    moment.locale('es');
    const formattedDate = moment(date).fromNow();
    const firstLeter = formattedDate.charAt(0).toUpperCase();
    const remaining = formattedDate.slice(1);
    return firstLeter + remaining;
};
