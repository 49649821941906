import React, { useRef, useState } from 'react';
import ImageClipIcon from '../../assets/icons/ImageClipIcon';
import { imageLoader } from '../../helpers';
import { useAsync } from '../../hooks';
import { useFetchAndLoad } from '../../hooks/useFetchAndLoad.hook';
import { getImageById } from '../../services';

interface imageFieldContainerProps {
    img: string;
    style?: React.CSSProperties;
}

const ImageFieldContainer: React.FC<imageFieldContainerProps> = ({ img, style }) => {
    const { callEndpoint } = useFetchAndLoad();
    const [image, setImage] = useState<string>('');
    const fileInputImage = useRef<HTMLInputElement>(null);

    const getImageApiCall = async () => {
        if (img != '') return await callEndpoint(getImageById(img));
    };

    const adaptData = (backData: any) => {
        if (backData) {
            setImage(imageLoader(backData));
            getFileFromBlob(imageLoader(backData));
        }
    };

    const getFileFromBlob = (blob: string) => {
        const file = new File([blob], `${img}.png`, { type: 'image/png', lastModified: new Date().getTime() });
        const container = new DataTransfer();
        container.items.add(file);
        if (fileInputImage.current) fileInputImage.current.files = container.files;
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            setImage(imageLoader(files[0]));
        }
    };

    useAsync(getImageApiCall, adaptData, [img]);

    return (
        <div className="image-field">
            <label htmlFor="image" className="name-container">
                <span>Imagen</span>
                <ImageClipIcon className="minuscule-icon" />
            </label>
            <input
                ref={fileInputImage}
                onChange={handleOnChange}
                className="form-field"
                type="file"
                id="image"
                accept="image/png, image/gif, image/jpeg"
            />
            <div className="image-container" style={style && style}>
                {image != '' ? <img src={image} alt="Imagen de evento" /> : <div className="no-image"></div>}
            </div>
        </div>
    );
};

export default ImageFieldContainer;
