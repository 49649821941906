import { FormAds } from './../models/Ads/FormAds.model';
import api from '../api';
import { loadAbort } from '../helpers';

export const getAdsByBannerType = (bannerType: number, pageSize = 1) => {
    const controller = loadAbort();
    return {
        call: api.get(`api/Ad/type/${bannerType}`, {
            headers: {
                pageSize: pageSize,
            },
            signal: controller.signal,
        }),
        controller,
    };
};

export const getAllAds = (pageSize = 10, pageNumber = 1) => {
    const controller = loadAbort();
    return {
        call: api.get('api/Ad', {
            headers: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
            signal: controller.signal,
        }),
        controller,
    };
};

export const createAds = (entity: FormAds) => {
    const controller = loadAbort();
    return {
        call: api.post('api/Ad', entity, { signal: controller.signal }),
        controller,
    };
};

export const editAds = (id: string, entity: FormAds) => {
    const controller = loadAbort();
    return {
        call: api.patch(`api/Ad/${id}`, entity, { signal: controller.signal }),
        controller,
    };
};

export const removeAds = (id: string) => {
    const controller = loadAbort();
    return {
        call: api.delete(`api/Ad/${id}`, { signal: controller.signal }),
        controller,
    };
};
