import React from 'react';
import LoadingIcons from 'react-loading-icons';

const LoadingComponent: React.FC = () => {
    return (
        <div className="loading-container">
            <div className="loading-component">
                <LoadingIcons.Oval className="loadin-icon" stroke="#9a0018" speed={2} width="4vmax" height="4vmax" strokeWidth="3" />
                <span>Cargando...</span>
            </div>
        </div>
    );
};

export default LoadingComponent;
