import React from 'react';
import IconProps from './IconProps.type';

const ClipIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 39 100" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.2994 28.1822L10.3545 28.1387L9.99797 68.6836C9.95376 73.7192 14.0146 77.852 19.0503 77.8963C24.0859 77.9406 28.2188 73.8798 28.263 68.844L28.7403 14.3905C28.7732 10.6431 27.3449 7.10723 24.7185 4.43423C22.092 1.76123 18.5818 0.271109 14.8344 0.238208C11.0872 0.205306 7.55129 1.63367 4.87829 4.26014C2.20517 6.88673 0.71505 10.3969 0.682265 14.1442L0.100958 80.3507C0.056568 85.4062 1.98349 90.1763 5.52673 93.7823C9.07009 97.3884 13.8055 99.3987 18.861 99.4431C23.9165 99.4874 28.6866 97.5605 32.2926 94.0173C35.8986 90.474 37.909 85.7386 37.9534 80.683L38.4125 28.3851L33.4677 28.3416L33.0086 80.6396C32.9759 84.3744 31.4907 87.8724 28.8267 90.49C26.1628 93.1076 22.6391 94.531 18.9044 94.4983C15.1697 94.4655 11.6716 92.9803 9.05401 90.3164C6.43643 87.6524 5.01301 84.1285 5.04569 80.3941L5.62711 14.1875C5.6712 9.17853 9.78223 5.13907 14.7912 5.18294C19.8003 5.22692 23.8398 9.33794 23.7958 14.347L23.3183 68.8003C23.298 71.1095 21.4028 72.9716 19.0937 72.9513C16.7846 72.9311 14.9224 71.0359 14.9427 68.7268L15.2994 28.1822Z"
                fill={props.fill || '#9A0018'}
            />
        </svg>
    );
};

export default ClipIcon;
