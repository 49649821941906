import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import RemoveIcon from '../../assets/icons/RemoveIcon';
import { NotifyContext } from '../../context/NotifyContext';
import { DateTimeAgo } from '../../helpers';
import { useAuth, useFetchAndLoad } from '../../hooks';
import { Desahogo } from '../../models';
import { removeDesahogo } from '../../services';
import ImageContainer from '../Templates/ImageContainer';

const DesahogoArticle: React.FC<Desahogo> = (desahogo) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { loading, callEndpoint } = useFetchAndLoad();
    const { notifyError, notifySuccess } = useContext(NotifyContext);

    const handleRemove = async () => {
        if (!loading) {
            if (confirm('¿Seguro que desea eliminar esta noticia?')) {
                try {
                    const result = await callEndpoint(removeDesahogo(desahogo.id));
                    if (result) {
                        notifySuccess('La noticia se ha eliminado correctamente');
                        navigate('/desahogo');
                    }
                } catch (e) {
                    notifyError('Ha ocurrido un error');
                    console.log(e);
                }
            }
        }
    };

    return (
        <article className="desahogo-article">
            <a target="_blank" rel="noopener noreferrer" href={desahogo.video} className="desahogo">
                <ImageContainer img={desahogo.img} />
                <div className="title">{desahogo.title}</div>
                <div className="date">
                    <span>{DateTimeAgo(desahogo.date)}</span>
                </div>
            </a>
            {user && <RemoveIcon onClick={handleRemove} className="tiny-static-plus-icon" />}
        </article>
    );
};

export default DesahogoArticle;
