import React, { useEffect } from 'react';
import RubenSanchezIcon from '../assets/icons/RubenSanchezIcon';
import Logo from '../assets/images/Logo.png';
import { useAuth, useFetchAndLoad } from '../hooks';
import { tokenStorage } from '../helpers';
import CallForLoginOrHandleRedirect from '../config/azure-ad/settings';
import { getCurrentUser } from '../services/User.service';
import { User } from '../models';

const AuthPage: React.FC = () => {
    const { user, login } = useAuth();
    const { callEndpoint: callLogin } = useFetchAndLoad();
    const getCurrentUserApiCall = async (token: string): Promise<User> => (await callLogin(getCurrentUser(token))).data;

    useEffect(() => {
        if (!user) {
            (async function mounted() {
                await CallForLoginOrHandleRedirect(onLoggedIn);
            })();
        }
    }, []);

    const onLoggedIn = async (tokenResponse: any) => {
        tokenStorage.set(tokenResponse.accessToken);
        const result = await getCurrentUserApiCall(tokenResponse);
        if (result) {
            login(result);
        }
    };

    return (
        <main className="auth-page">
            <div className="logo-container">
                <img className="logo" src={Logo} alt="Logo" />
                <RubenSanchezIcon className="large-icon" />
                <h2>Autenticación</h2>
                {user && (
                    <p>
                        Bienvenido {user.name} {user.surname}
                    </p>
                )}
            </div>
        </main>
    );
};

export default AuthPage;
