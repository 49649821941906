import React, { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '../../assets/icons/EditIcon';
import RemoveIcon from '../../assets/icons/RemoveIcon';
import { NotifyContext } from '../../context/NotifyContext';
import { DateTimeAgo } from '../../helpers';
import { useAuth, useFetchAndLoad } from '../../hooks';
import { CalendarEvent } from '../../models';
import { removeCalendarEvent } from '../../services';
import ImageContainer from '../Templates/ImageContainer';

const CalendarEventArticle: React.FC<CalendarEvent> = (event) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { notifySuccess, notifyError } = useContext(NotifyContext);
    const { loading, callEndpoint } = useFetchAndLoad();

    const handleRemove = async () => {
        if (!loading) {
            if (confirm('¿Seguro que desea eliminar esta noticia?')) {
                try {
                    const result = await callEndpoint(removeCalendarEvent(event.id));
                    if (result) {
                        notifySuccess('La noticia se ha eliminado correctamente');
                        if (pathname.includes('news')) navigate('/');
                        else navigate(0);
                    }
                } catch (e) {
                    notifyError('Ha ocurrido un error');
                    console.log(e);
                }
            }
        }
    };

    return (
        <article className="event-article">
            <ImageContainer img={event.img} />
            <div className="event-content">
                <div className="title">{event.title}</div>
                <div className="description">{event.description}</div>
                <div className="date">
                    <span>{DateTimeAgo(event.date)}</span>
                    {user && (
                        <div className="action-buttons">
                            <Link to={`/edit/calendario/${event.id}`} className="edit-icon-container">
                                <EditIcon className="tiny-static-plus-icon" />
                            </Link>
                            <RemoveIcon onClick={handleRemove} className="tiny-static-plus-icon" />
                        </div>
                    )}
                </div>
            </div>
        </article>
    );
};

export default CalendarEventArticle;
