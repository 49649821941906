import React, { useContext } from 'react';
import LoadingIcons from 'react-loading-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '../../assets/icons/EditIcon';
import RemoveIcon from '../../assets/icons/RemoveIcon';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
import { NotifyContext } from '../../context/NotifyContext';
import { DateTimeAgo } from '../../helpers';
import { useAuth, useFetchAndLoad } from '../../hooks';
import { News } from '../../models';
import { removeNews } from '../../services/News.service';
import ImageContainer from '../Templates/ImageContainer';

const NewsArticle: React.FC<News> = (news) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { notifyError, notifySuccess } = useContext(NotifyContext);
    const { loading, callEndpoint } = useFetchAndLoad();
    const description = news.description.length < 300 ? news.description : `${news.description.slice(0, 300)}...`;

    const handleRemove = async () => {
        if (!loading) {
            if (confirm('¿Seguro que desea eliminar esta noticia?')) {
                try {
                    const result = await callEndpoint(removeNews(news.id));
                    if (result) {
                        notifySuccess('La noticia se ha eliminado correctamente');
                        if (pathname.includes('news')) navigate('/');
                        else navigate(0);
                    }
                } catch (e) {
                    notifyError('Ha ocurrido un error');
                    console.log(e);
                }
            }
        }
    };

    return (
        <article className="news-article">
            <ImageContainer img={news.img} />
            <div className="info-container">
                <div className="date">{DateTimeAgo(news.date)}</div>
                <div className="title">{news.title}</div>
                <div className="description">{description}</div>
                <div className="options">
                    <Link className="more" to={`/news/${news.id}`}>
                        <span>Saber más</span>
                        <RightArrowIcon className="tiny-icon" />
                    </Link>
                    {user && (
                        <div className="action-buttons">
                            <Link to={`/edit/news/${news.id}`} className="edit-icon-container">
                                <EditIcon className="tiny-static-plus-icon" />
                            </Link>
                            {!loading ? (
                                <RemoveIcon onClick={handleRemove} className="tiny-static-plus-icon" />
                            ) : (
                                <LoadingIcons.Oval stroke="#9a0018" width="2rem" height="2rem" />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </article>
    );
};

export default NewsArticle;
