import React from 'react';
import SocialLinks from './SocialLinks';
import { ZoneEnum } from '../../models';
import Logo from '../../assets/images/Logo.png';
import RubenSanchezIcon from '../../assets/icons/RubenSanchezIcon';
import LinksContainer from './LinksContainer';

const Footer: React.FC = () => {
    return (
        <footer>
            <div className="logo-container">
                <img className="logo" src={Logo} alt="Logo" />
                <RubenSanchezIcon className="large-icon" />
            </div>
            <div className="footer-menu">
                <LinksContainer />
            </div>
            <SocialLinks zone={ZoneEnum.Footer} />
            <div className="copyright">@2023 Todos los Derechos Reservados</div>
        </footer>
    );
};

export default Footer;
