import api from '../api';
import { loadAbort } from '../helpers';

export const suscribeMail = (mail: string) => {
    const controller = loadAbort();
    return {
        call: api.post('api/Subscriber', undefined, { params: { email: mail }, signal: controller.signal }),
        controller,
    };
};
