import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const LinksContainer: React.FC = () => {
    const location = useLocation();

    const menuOptions = [
        {
            label: 'Home',
            value: '/',
        },
        {
            label: 'El Desahogo',
            value: '/desahogo',
        },
        {
            label: 'Actividades',
            value: '/calendario',
        },
    ];
    return (
        <ul className="links-container">
            {menuOptions.map((option, i) => {
                const selected = location.pathname == option.value ? 'selected' : '';
                return (
                    <li key={i}>
                        <Link className={`link ${selected}`} to={option.value}>
                            {option.label}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

export default LinksContainer;
