import React from 'react';
import Logo from '../assets/images/Logo.png';
import RubenSanchezIcon from '../assets/icons/RubenSanchezIcon';

const NotFoundPage: React.FC = () => {
    return (
        <main className="not-found-page">
            <div className="logo-container">
                <img className="logo" src={Logo} alt="Logo" />
                <RubenSanchezIcon className="large-icon" />
                <h2>Pagina no encontrada</h2>
            </div>
        </main>
    );
};

export default NotFoundPage;
