import api from '../api';
import { loadAbort } from '../helpers';
import { FormDesahogo } from '../models';

export const getAllDesahogo = (pageSize = 10, pageNumber = 1) => {
    const controller = loadAbort();
    return {
        call: api.get('api/Reliefs', {
            headers: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },
            signal: controller.signal,
        }),
        controller,
    };
};

export const createDesahogo = (entity: FormDesahogo) => {
    const controller = loadAbort();
    return {
        call: api.post('api/Reliefs', entity, { signal: controller.signal }),
        controller,
    };
};

export const removeDesahogo = (id: string) => {
    const controller = loadAbort();
    return {
        call: api.delete(`api/Reliefs/${id}`, { signal: controller.signal }),
        controller,
    };
};
