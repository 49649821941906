import { AuthContext } from './../context/AuthContext';
import { currentUserStorage, tokenStorage } from '../helpers/LocalStorage.utility';
import { useContext } from 'react';
import { User } from '../models';

export const useUser = () => {
    const { user, setUser } = useContext(AuthContext);

    const addUser = (userData: User) => {
        setUser(userData);
        currentUserStorage.set(userData);
    };

    const removeUser = () => {
        setUser(undefined);
        tokenStorage.remove();
        currentUserStorage.remove();
    };

    return { user, addUser, removeUser };
};
