import React, { useEffect } from 'react';
import './assets/styles/App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './components/layout/Footer';
import Header from './components/layout/Header';
import HomePage from './pages/HomePage';
import CalendarPage from './pages/Calendar/CalendarPage';
import DesahogoPage from './pages/Desahogo/DesahogoPage';
import NotFoundPage from './pages/NotFoundePage';
import NewsDetailPage from './pages/News/NewsDetailPage';
import NewsSearchPage from './pages/News/NewsSearchPage';
import { ScrollToTop, useAuth, useFetchAndLoad } from './hooks';
import AuthPage from './pages/AuthPage';
import { getCurrentUser } from './services/User.service';
import { tokenStorage } from './helpers';
import NewsFormPage from './pages/News/NewsFormPage';
import DesahogoFormPage from './pages/Desahogo/DesahogoFormPage';
import CalendarFormPage from './pages/Calendar/CalendarFormPage';
import AdsPage from './pages/Ads/AdsPage';

const App: React.FC = () => {
    const { user, login, logout } = useAuth();
    const { callEndpoint } = useFetchAndLoad();
    const getCurrentUserApiCall = async (token: string) => await callEndpoint(getCurrentUser(token));

    const checkAuthentication = async () => {
        if (!user) {
            const authToken = tokenStorage.get();
            if (authToken) {
                const result = await getCurrentUserApiCall(authToken);
                if (result?.data?.id) {
                    login(result.data);
                }
            } else {
                logout();
            }
        }
    };

    useEffect(() => {
        checkAuthentication();
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/news" element={<NewsSearchPage />} />
                    <Route path="/search" element={<NewsSearchPage />} />
                    <Route path="/search/:name" element={<NewsSearchPage />} />
                    <Route path="/news/:id" element={<NewsDetailPage />} />
                    <Route path="/edit/news/:id" element={<NewsFormPage />} />
                    <Route path="/create/news" element={<NewsFormPage />} />
                    <Route path="/desahogo" element={<DesahogoPage />} />
                    <Route path="/create/desahogo" element={<DesahogoFormPage />} />
                    <Route path="/calendario" element={<CalendarPage />} />
                    <Route path="/edit/calendario/:id" element={<CalendarFormPage />} />
                    <Route path="/create/calendario" element={<CalendarFormPage />} />
                    <Route path="/authentication" element={<AuthPage />} />
                    <Route path="/ads" element={<AdsPage />} />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <Footer />
            </BrowserRouter>
        </div>
    );
};

export default App;
