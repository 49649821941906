import React, { createContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';

interface NotifyContextProps {
    notifySuccess: (text: string) => void;
    notifyError: (text: string) => void;
}

export const NotifyContext = createContext<NotifyContextProps>({
    notifySuccess: (text: string) => {
        toast.success(text);
    },
    notifyError: (text: string) => {
        toast.error(text);
    },
});

interface NotifyProviderProps {
    children: JSX.Element;
}

export const NotifyProvider: React.FC<NotifyProviderProps> = ({ children }) => {
    const notifySuccess = (text: string) => {
        toast.success(text);
    };

    const notifyError = (text: string) => {
        toast.error(text);
    };

    return (
        <NotifyContext.Provider value={{ notifySuccess, notifyError }}>
            <Toaster position="top-right" reverseOrder={false} />
            {children}
        </NotifyContext.Provider>
    );
};
