import React, { useState } from 'react';
import BottomAdd from '../../components/Ads/BottomAds';
import SidebarAds from '../../components/Ads/SidebarAds';
import { CalendarEvent } from '../../models';
import EventArticle from '../../components/Calendar/CalendarEventArticle';
import { useFetchAndLoad } from '../../hooks/useFetchAndLoad.hook';
import { getAllCalendarEvents } from '../../services';
import { useAsync, useAuth } from '../../hooks';
import AddIcon from '../../assets/icons/AddIcon';
import { Link } from 'react-router-dom';
import LoadingComponent from '../../components/Templates/LoadingComponent';
import ListPagination from '../../components/Templates/ListPagination';

const CalendarPage: React.FC = () => {
    const { user } = useAuth();
    const { loading, callEndpoint } = useFetchAndLoad();
    const [data, setData] = useState<CalendarEvent[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const getCalendarEventsApiCall = async () => await callEndpoint(getAllCalendarEvents(10, pageNumber));

    const adaptData = (backData: any) => {
        if (backData.items) setData(backData.items);
    };

    useAsync(getCalendarEventsApiCall, adaptData, []);

    return (
        <main className="calendar-page">
            <div className="page-container">
                <div className="content-container">
                    <div className="content">
                        <div className="content-title">
                            <h2 className="title">Actividades</h2>
                            {user && (
                                <Link to="/create/calendario" className="add-container">
                                    <AddIcon className="tiny-static-plus-icon" />
                                    <span>Agregar evento</span>
                                </Link>
                            )}
                        </div>
                        <section className="events-container">
                            {!loading ? (
                                data.length > 0 ? (
                                    data.map((event) => {
                                        return <EventArticle {...event} key={event.id} />;
                                    })
                                ) : (
                                    <h4 className="empty">No hay eventos publicados</h4>
                                )
                            ) : (
                                <LoadingComponent />
                            )}
                            <ListPagination
                                pageNumber={pageNumber}
                                setPageNumber={setPageNumber}
                                getApiCall={getCalendarEventsApiCall}
                                adaptData={adaptData}
                            />
                        </section>
                        <BottomAdd />
                    </div>
                    <SidebarAds />
                </div>
            </div>
        </main>
    );
};

export default CalendarPage;
