import React from 'react';
import SidebarAds from '../Ads/SidebarAds';
import LastNewsSection from '../Home/LastNewsSection';
import Newsletter from '../Home/Newsletter';
import ProfileCard from '../Home/ProfileCard';

const MainSidebar: React.FC = () => {
    return (
        <aside className="main-sidebar">
            <ProfileCard />
            <LastNewsSection />
            <Newsletter />
            <SidebarAds />
        </aside>
    );
};

export default MainSidebar;
