import React from 'react';
import IconProps from './IconProps.type';

const RightArrowIcon: React.FC<IconProps> = (props) => {
    return (
        <svg className={props.className} onClick={props.onClick} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.172 6.778L6.808 1.414L8.222 0L16 7.778L8.222 15.556L6.808 14.142L12.172 8.778H0V6.778H12.172Z"
                fill={props.fill || '#9A0018'}
            />
        </svg>
    );
};

export default RightArrowIcon;
